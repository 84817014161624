<template>
    <div></div>
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";

export default {
    name: "ExcelExport",
    methods: {
        downloadExcel(data, columns, fileName) {
            console;

            const DATASOURCE = data,
                HEAD = columns,
                FILENAME = fileName;

            jsontoexcel.getXlsx(DATASOURCE, HEAD, FILENAME);
        },
    },
};
</script>
